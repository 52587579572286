<template>
    <div>
      <v-layout class="mainfont" wrap justify-center>
        <v-snackbar v-model="showSnackBar" color="black">
          <v-layout wrap justify-center>
            <v-flex text-left class="align-self-center">
              <span style="color: white">
                {{ msg }}
              </span>
            </v-flex>
            <v-flex text-right>
              <v-btn small :ripple="false" text @click="showSnackBar = false">
                <v-icon style="color: white">mdi-close</v-icon>
              </v-btn>
            </v-flex>
          </v-layout>
        </v-snackbar>
        <vue-element-loading
          :active="appLoading"
          :is-full-screen="true"
          background-color="#FFFFFF"
          color="#13736f"
        spinner="spinner"
        />
        <v-flex xs12>
          <v-layout wrap x pl-3 pt-7 justify-center>
            <v-flex xs10>
              <v-card elevation="0" class="pl-16 pr-16 pt-16" style="border-radius: 0px">
                <v-layout wrap justify-center>
                  <v-card elevation="0"  class="pa-5 mainbg4" style="border-radius: 0px">
                    <v-flex xs12>
                      <v-flex
                        xs12
                        class="mainfont"
                        style="color: black; font-size: 20px; font-weight: bold"
                      >
                        {{ list.title }}
                      </v-flex>
                      <v-flex xs12>
                        <span
                          class="mainfont"
                          style="
                            color:#757575;
                            font-size: 13px;
                            font-weight: lighter;
                          "
                          >{{ formatDate(list.create_date) }}</span
                        >
                      </v-flex>
                      <v-flex
                        xs12
                     
                        class="mainfont"
                        style="
                          color: black;
                          font-size: 23px;
                          font-weight: lighter;
                        "
                      >
                        <v-img
                     
                        contain
                          :src="mediaURLnew + list.photo"
                        >
                        </v-img>
                      </v-flex>
                      <v-flex xs12 pt-3>
                      
                      <span  style="
                          color: black;
                          font-size: 13px;
                          font-weight: lighter;
                        " v-html="list.content"></span>
                      </v-flex>
                    </v-flex>
                  </v-card>
                </v-layout>
              </v-card>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </div>
  </template>
      
      
      <script>
  import axios from "axios";
  export default {
    data() {
      return {
        preview: null,
        link: null,
        page: 1,
        editdailog: false,
  
        currentpage: 1,
        showSnackBar: false,
        msg: null,
        limit: 10,
        url: null,
        categoryName: null,
        id: this.$route.query.id,
  
        appLoading: false,
        name: null,
        description: null,
        duration: null,
        pages: 0,
        icon: null,
        formData: new FormData(),
  
        listingOrder: null,
        list: {},
        photos: [],
        deletedialog: false,
        editdialog: false,
        noOfDays: null,
        amount: null,
        dialog2: false,
      };
    },
    mounted() {
      this.getList();
    },
  
    methods: {
      formatDate(item) {
        var dt = new Date(item);
        var year = dt.getFullYear();
        dt = dt.toString();
        var strTime = dt.slice(4, 10) + " " + year;
        return strTime;
      },
      getList() {
        this.appLoading = true;
        axios({
          method: "GET",
          url: "/news/view/" + this.id,
          headers: {
            token: localStorage.getItem("token"),
          },
        })
          .then((response) => {
            this.photos = response.data.data.photos;
            this.list = response.data.data;
  
            this.appLoading = false;
          })
          .catch((err) => {
            this.ServerError = true;
            console.log(err);
          });
      },
    },
  };
  </script>
      
      
      <style scoped>
  .flex-red {
    background-color: #13736f;
  }
  
  .gx {
    background-color: red;
  }
  .bg8 {
    background: var(
      --grend,
      linear-gradient(
        252deg,
        #e4ecee 0%,
        #9bc2c2 0%,
        #6ca6a5 28%,
        #157470 88%,
        #13736f 100%
      )
    );
  }
  .justified-text {
    text-align: justify;
  }
  .mainbg4 {
    background-image: linear-gradient(269.6deg, #fbfbfb -31.66%, #fbfbfb);
  }
  
  </style>